<template>
  <div
      :class="
      `position-relative kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed
   kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed
   `
    "
  >
    <div class="kt-grid kt-grid--hor kt-grid--root">
      <div
          class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
      >
        <div
            id="kt_aside"
            :class="
            `kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop ${
              is_open_aside
                ? 'kt-aside--on kt-aside--minimize'
                : 'kt-aside--enabled'
            }`
          "
        >
          <!-- begin:: Aside -->
          <div id="kt_aside_brand" class="kt-aside__brand kt-grid__item d-flex">
            <h2 class="text-center m-0 d-flex">
              <router-link to="/" class="text-white my-auto"
              >SweetCheckout - One Click Sell
              </router-link>
            </h2>
          </div>
          <aside-menu/>
        </div>
        <div
            v-if="is_open_aside"
            class="kt-aside-overlay"
            @click="toggleAside(!is_open_aside)"
        ></div>
        <div
            id="kt_wrapper"
            :class="
            `kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper full-page kt-aside--enabled`
          "
        >
          <header-menu/>
          <router-view v-if="isLadedShop"></router-view>
          <our-apps/>
<!--          <footer-component/>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'
import HeaderMenu from '../components/HeaderMenu.vue'
import AsideMenu from '../components/AsideMenu.vue'
import OurApps from '../components/OurApps'
import {LOAD} from '../store/modules/shop/types';
import {ON_TOGGLE_ASIDE} from '../store/modules/layout/types';
import {requestStatus} from "@/services/services";

export default {
  name: 'auth-page',
  components: {
    HeaderMenu, AsideMenu, OurApps
  },
  data: () => ({
    show_topbar: false,
    show_aside: false,
    isLadedShop: false,
  }),
  computed: {
    ...mapState({
      is_open_aside: state => state.layout.is_open_aside,
      status: state => state.auth.status,
      shopStatus: state => state.shop.status,
      isAuthenticated: state => state.auth.isAuthenticated,
    })
  },
  methods: {
    ...mapMutations({
      toggleAside: `layout/${ON_TOGGLE_ASIDE}`,
    }),
    ...mapActions({
      loadShop: `shop/${LOAD}`,
    }),
  },
  watch: {
    $route() {
      this.toggleAside(false)
    },
    status(newValue) {
      if(newValue.logout && newValue.logout !== requestStatus.loading){
        this.$router.push({ name: 'login' });
      }
    },
    shopStatus(newValue) {
      if(newValue.change_field === 'load' && newValue.load === requestStatus.success) {
        this.isLadedShop = true;
      }
    }
  },
  created: function () {
    if(!this.isAuthenticated){
      this.$router.push({ name: 'login' });
    }
    else{
      this.loadShop();
    }
  }
}
</script>

<style scoped>

</style>